import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { engQuotes, hinQuotes, kanQuotes } from "./Quotes";
import i18next from "i18next";
import Typewriter from "typewriter-effect";
import {
  Button,
  Tooltip,
  Box,
  Typography,
  Card,
  Grid,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Fade from "react-reveal/Fade";
// import "./HomeLanding.css"; // Optional for additional custom styles

function HomeLanding() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [quotes, setQuotes] = useState([]);
  const [quoteIndex, setQuoteIndex] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const user = JSON.parse(localStorage.getItem("user")) || { username: "Guest" };

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const isFirstTimeUser = localStorage.getItem("first_time_user") === "true";
        setShowTooltip(isFirstTimeUser);

        const currentLanguage = i18next.language;
        let loadedQuotes;

        if (currentLanguage === "en") loadedQuotes = engQuotes;
        else if (currentLanguage === "hi") loadedQuotes = hinQuotes;
        else if (currentLanguage === "ka") loadedQuotes = kanQuotes;
        else {
          const response = await axios.get("https://type.fit/api/quotes");
          loadedQuotes = response.data.slice(0, 20);
        }

        setQuotes(loadedQuotes);
        setQuoteIndex(Math.floor(Math.random() * loadedQuotes.length));
      } catch (error) {
        console.error("Error fetching quotes:", error);
      }
    };

    fetchQuotes();
  }, []);

  const handleNavigate = (path) => {
    localStorage.setItem("first_time_user", "false");
    navigate(path);
  };

  const renderQuote = () => {
    if (quoteIndex === null) return "Loading...";
    const { text, author } = quotes[quoteIndex];
    return (
      <>
        <Typewriter
          options={{
            strings: text || quotes[quoteIndex],
            autoStart: true,
            loop: false,
          }}
        />
        <Typography variant="body2" color="textSecondary" mt={2}>
          {author ? `- ${author}` : "Unknown"}
        </Typography>
      </>
    );
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        px: 0,
        py: 6,
        background: "linear-gradient(135deg, #428CFB, #6AD1FF)",
      }}
    >
      {/* Welcome Header */}
      <Typography
        variant="h3"
        component="h1"
        sx={{ color: "white", fontWeight: "bold", mb: 2 }}
      >
        <Fade top>
          {t("Hey")} <span style={{ color: "#FFD88D" }}>{user.username}</span>
        </Fade>
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ color: "white", fontSize: "1.2rem", mb: 4 }}
      >
        <Fade bottom>{t("Here's a Quote for you,")}</Fade>
      </Typography>

      {/* Quotes Section */}
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Fade left>
            <Card
              sx={{
                p: 4,
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                borderRadius: "15px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                transition: "transform 0.3s ease",
                "&:hover": { transform: "scale(1.02)" },
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: "1.5rem",
                  color: "#333",
                  fontWeight: "bold",
                }}
              >
                {renderQuote()}
              </Typography>
            </Card>
          </Fade>
        </Grid>
      </Grid>

      {/* Buttons */}
      <Box mt={4}>
        {showTooltip ? (
          <Tooltip
            open={showTooltip}
            title="Welcome to Gyani! Ready for your writing adventure? Let's start!"
            placement="top"
            arrow
            sx={{
              "& .MuiTooltip-tooltip": {
                backgroundColor: "#0E0E2C",
                color: "white",
              },
            }}
          >
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              onClick={() => handleNavigate("/dashboard/mynotes")}
              sx={{
                backgroundColor: "#FFD88D",
                color: "#333",
                fontWeight: "bold",
                boxShadow: "0px 4px 15px rgba(0,0,0,0.2)",
                "&:hover": {
                  backgroundColor: "#FFC947",
                  boxShadow: "0px 6px 20px rgba(0,0,0,0.3)",
                },
              }}
            >
              Get Started
            </Button>
          </Tooltip>
        ) : (
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            onClick={() => handleNavigate("/dashboard/mynotes")}
            sx={{
              backgroundColor: "#FFD88D",
              color: "#333",
              fontWeight: "bold",
              boxShadow: "0px 4px 15px rgba(0,0,0,0.2)",
              "&:hover": {
                backgroundColor: "#FFC947",
                boxShadow: "0px 6px 20px rgba(0,0,0,0.3)",
              },
            }}
          >
            New Note
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default HomeLanding;
