import React, { useState } from "react";
import {
  IconButton,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import {
  Menu,
  ChevronLeft,
  Home,
  NotesOutlined,
  BookmarkBorderOutlined,
  ChatBubbleOutline,
  SettingsOutlined,
  AccountCircle,
  Payment,
  ElectricBolt,
} from "@mui/icons-material";
import gyani from "../SVGAvatars/fullLogo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Sidebar = ({ isSidebarOpen, setSidebarOpen}) => {
  const location = useLocation();
  // const isMobile = useMediaQuery("(max-width: 768px)"); // Detect screen width

  // const [mobileOpen, setMobileOpen] = useState(false); // State for mobile drawer

  const navItems = [
    { text: "Home", icon: <Home />, path: "/dashboard/home" },
    { text: "My Notes", icon: <NotesOutlined />, path: "/dashboard/mynotes" },
    { text: "Bookmarks", icon: <BookmarkBorderOutlined />, path: "/dashboard/bookmarks" },
    // { text: "Chat Bot", icon: <ChatBubbleOutline />, path: "/dashboard/chatbot" },
    { text: "Profile", icon: <AccountCircle />, path: "/dashboard/profile" },
    { text: "Subscriptions", icon: <Payment />, path: "/dashboard/subscriptions" },
  ];

  const isActive = (path) => location.pathname === path;

  const user = JSON.parse(localStorage.getItem('user'));

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const SidebarContent = (
    <Box
      sx={{
        width: isSidebarOpen ? 228 : 0,
        transition: "width 0.2s",
        backgroundColor: "#f1f5f9",
        height: "100vh",
        pt: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRight: "1px solid #D1D5DB",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: 1 }}>
        {isSidebarOpen && (
          <img src={gyani} alt="Logo" style={{ height: 40, marginLeft: 8 }} />
        )}
        <IconButton onClick={toggleSidebar}>
          {isSidebarOpen ? <ChevronLeft /> : <div/>}
        </IconButton>
      </Box>

      {/* Navigation Links */}
      <List className={`!text-sm ${!isSidebarOpen && 'hidden'}`}>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              component={Link}
              to={item.path}
              sx={{
                backgroundColor: isActive(item.path) ? "inherit" : "inherit",
                color: isActive(item.path) ? "#4f46e5" : "inherit",
                "&:hover": { backgroundColor: "#E2E8F0" },
                paddingY:0.5,
              }}
              // onClick={()=> setSidebarOpen(false)}
            >
              <ListItemIcon
                sx={{
                  color: isActive(item.path) ? "#4f46e5" : "inherit",
                  // justifyContent: "center",
                }}
              >
                {item.icon}
              </ListItemIcon>
              {(isSidebarOpen) && <ListItemText primary={item.text} />}
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Divider />

      {/* Settings Link */}
      <List sx={{ mb: 2 }} className={`${!isSidebarOpen && 'hidden'}`}>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/dashboard/settings"
            sx={{
              backgroundColor: isActive("/dashboard/settings") ? "#D1D5DB" : "inherit",
              color: isActive("/dashboard/settings") ? "#000" : "inherit",
              "&:hover": { backgroundColor: "#D1D5DB" },
            }}
            // onClick={()=> setSidebarOpen(false)}
          >
            <ListItemIcon
              sx={{
                color: isActive("/dashboard/settings") ? "#4A4A68" : "inherit",
                // justifyContent: "center",
              }}
            >
              <SettingsOutlined />
            </ListItemIcon>
            {(isSidebarOpen) && <ListItemText primary="Settings" />}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
        <ListItemButton
            component={Link}
            to="/dashboard/subscriptions"
            sx={{
              backgroundColor: isActive("/dashboard/subsriptions") ? "#D1D5DB" : "inherit",
              color: isActive("/dashboard/subscriptions") ? "#000" : "inherit",
              "&:hover": { backgroundColor: "#D1D5DB" },
            }}
          >
        <div className={`m-auto  inset-x-0 bottom-0 w-[94%] ${!isSidebarOpen && 'hidden'}`}>
        <div className="flex flex-col justify-between mb-1 w-[100%]">
          <div className="flex w-full justify-between items-center text-xs">
            <span>AI Limit</span>
            <span className="font-medium text-blue-500">{user.credits_left}/1000</span>
          </div>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-blue-400 h-2.5 rounded-full"
            style={{ width: (user.credits_left/1000)*100 + "%" }}
          ></div>
        </div>
        <button className="bg-blue-400 text-xs text-white p-2 w-full mt-2 rounded-lg">
          <ElectricBolt/> See Pricing
        </button>
      </div>
      </ListItemButton>
        </ListItem>
      </List>


      {/* <List sx={{ mb: 2 }}>
        <ListItem disablePadding>
        <ListItemButton
            component={Link}
            to="/dashboard/subscriptions"
            sx={{
              backgroundColor: isActive("/dashboard/subsriptions") ? "#D1D5DB" : "inherit",
              color: isActive("/dashboard/subscriptions") ? "#000" : "inherit",
              "&:hover": { backgroundColor: "#D1D5DB" },
            }}
          >
        <div className="m-auto  inset-x-0 bottom-0 w-[94%]">
        <div className="flex justify-between mb-1 w-[100%]">
          <span
            className="text-base font-medium text-blue-500 cursor-pointer"
          >
            <span>Upgrade</span> <ArrowRightAlt/>
          </span>
          <span className="text-sm font-medium text-blue-500 ">
            {user.credits_left}
          </span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-blue-500 h-2.5 rounded-full"
            style={{ width: "50%" }}
          ></div>
        </div>
      </div>
      </ListItemButton>
        </ListItem>
      </List> */}
      
    </Box>
  );

  return SidebarContent;
    // <>
    //   {/* Top App Bar for Mobile */}
    //   <AppBar position="fixed">
    //     <Toolbar sx={{backgroundColor:"#EFEFFD",color:"black"}}>
    //       <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleSidebar}>
    //         <Menu />
    //       </IconButton>
    //       <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
    //         Gyani
    //       </Typography>
    //     </Toolbar>
    //   </AppBar>
    //   {/* Drawer for Mobile */}
    //   <Drawer
    //     anchor="left"
    //     open={mobileOpen}
    //     onClose={toggleSidebar}
    //     ModalProps={{
    //       keepMounted: true, // Better open performance on mobile
    //     }}

    //     variant="temporary"
    //   >
    //     {SidebarContent}
    //   </Drawer>
    // </>
};

export default Sidebar;
