import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import HomePage from "./components/pg_before_landing/HomePage";
import Signup from "./components/LoginPage/Signup";
import UserProvider from "./components/context/AuthContext";
import Login1 from "./components/LoginPage/Login1";
import Login2 from "./components/LoginPage/Login2";
import Login4 from "./components/LoginPage/Login4";
import Forget from "./components/LoginPage/Forgetpass";
import Payable from "./components/Subsriptions/Payable";
import NotFound from "./components/NotFound";
import Paymentreciept from "./components/Subsriptions/Paymentreciept";
import './i18next';
import './index.css';
import Invoice from "./components/Subsriptions/Invoice"
import { MantineProvider } from "@mantine/core";
import DotRingCursor from "./components/DotRingCursor/DotRingCursor";
import SignIn from "./components/LoginPage/SignIn";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div>
    <MantineProvider>
      <UserProvider>
        {/* <div className="md:block lg:block"><DotRingCursor/></div> */}
        <BrowserRouter>
          <Routes>
            {/* <Route exact path="/*" element={<HomePage />}></Route> */}
            <Route exact path="/login" element={<Login1 />} />
            <Route exact path="/payable" element={<Payable />} />
            <Route exact path="/reciept" element={<Paymentreciept />} />
            <Route exact path="/invoice" element={<Invoice />} />
            <Route exact path="/login/password" element={<Login2 />} />
            <Route exact path="/signin" element={<SignIn />} />
            <Route exact path="/signin" element={<Login4 />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/forget" element={<Forget />} />
            {
              JSON.parse(localStorage.getItem('user')) ?
              <>
                  <Route exact path="/*" element={<App />} />
                  <Route exact path="/dashboard*" element={<App />} />
              </>
                :
                <>
                  <Route exact path="/*" element={<Navigate to="/signin" />} />
                </>
            }
            <Route exact path="/notfound" element={<NotFound />}></Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        {/* </React.StrictMode> */}
      </UserProvider>
      </MantineProvider>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
