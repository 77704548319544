import Pricing from "./Pricing";

function Subscriptions() {
  return (
    <div>
      <Pricing />
    </div>
  );
}

export default Subscriptions;
