import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Loading from "./Loading";
import CiteCard from "../SummaryGenerator/components/CiteCard";

const Bookmarks = () => {
  const [bookmarks, setBookmarks] = useState([]);
  const [loading, setLoading] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));

  const handleView = (url) => {
    window.open(url, "_blank");
  };

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await axios.post(
        "https://api.heygyani.com/get-bookmarks/",
        {
          userid: user.id,
        }
      );
      setBookmarks(response.data);
      localStorage.setItem('bookmarks', JSON.stringify(response.data))
      console.log(response.data)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching bookmarks:", error);
      setLoading(false);
    }
  }, [user.id]);

  useEffect(() => {
    fetchData();
    console.log(bookmarks)
  }, [fetchData]);

  const handleDeleteBookmark = async (id) => {
    setLoading(true)
    const payload = {
      userid: user.id,
      bookmark_id: id,
    };
  
    try {
      var res = await axios.delete("https://api.heygyani.com/delete_paper_bookmarks/", {
        data: payload,
      });
  
      setBookmarks((prevBookmarks) =>
        prevBookmarks.filter((bookmark) => bookmark.paper_id !== id)
      );
      localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
      setLoading(false);
      return res.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  };
  

  const setBookmark = async (id, title, paperId, url) => {
    setLoading(true);
    try {
      let data = JSON.stringify({
        title: title,
        userid: id,
        url: url,
        paper_id: paperId,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.heygyani.com/paper_bookmark/",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      const storedBookmarks = JSON.parse(localStorage.getItem('bookmarks')) || []; // Fallback to an empty array if no bookmarks exist
      const updatedBookmarks = [...storedBookmarks, {
        title: title,
        userid: id,
        url: url,
        paper_id: paperId,
      }];
      localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
      setLoading(false);
      return response.data;
    } catch (error) {
      // setisBookmarked(false);
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received from the server");
      } else {
        console.error("Error setting up the request:", error.message);
      }
      setLoading(false);
    }
  }

  return (
    <div className="m-auto items-center">
      <div className="text-3xl font-bold my-5 text-center text-blue-500">
        Bookmarks
      </div>
      {loading && <Loading />}
      <div className="flex w-full justify-center items-center gap-2 flex-wrap">
        {bookmarks?.length >= 1 &&
          bookmarks.map(
            (bookmark, key) =>
              bookmark && (
                <div key={key} className="lg:w-1/3">
                  <CiteCard
                    setLoading={setLoading}
                    data={bookmark}
                    onViewInNewTab={() => handleView(bookmark.paper_url)}
                    onDeleteBookmark={() => handleDeleteBookmark(bookmark.paper_id)}
                    fetchData={fetchData}
                    type='bookmark'
                    setBookmark={() => setBookmark(
                      user.id,
                      bookmark.paper_title,
                      bookmark.paper_id,
                      bookmark.paper_url
                    )
                    }
                  />
                </div>
              )
          )}
      </div>
    </div>
  );
};

export default Bookmarks;