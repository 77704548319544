import React, { useEffect, useState, useRef } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { AutoAwesome, Send, Sync, AttachFile } from "@mui/icons-material";
import axios from "axios";
import ChatbotLogo from "../../../src/SVGAvatars/Chatbot_logo.svg";
import { ActionIcon, TextInput } from "@mantine/core";

function AIBot(props) {
  let user = JSON.parse(localStorage.getItem("user"));
  const [displayMessages, setDisplayMessages] = useState([]);
  const [msg, setMsg] = useState("");
  const [input, setInput] = useState(false);
  const [file, setFile] = useState(null);
  const bottomRef = useRef(null);

  useEffect(() => {
    if (props.type === 'chatpdf') {
      const msgs = JSON.parse(localStorage.getItem("chatpdfchats"));
      if (msgs) setDisplayMessages(msgs);
    }
    else{
      const msgs = JSON.parse(localStorage.getItem("chats"));
      if (msgs) setDisplayMessages(msgs);
    }
  }, []);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [displayMessages]);

  useEffect(() => {
    if(file) uploadFile()
  },[file])

  const uploadFile = async () => {
    if (!file) return;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", user.id);
    formData.append("filename", file.name);

    console.log(formData)

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.heygyani.com/save_doc_api/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials:true
    };

    try {
      let response = await axios({
        method: "post",
        url: "https://api.heygyani.com/save_doc_api/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      
      // request(config);
      props.setshowchatwithpdf(true);
      props.setshowsearch(false);
      console.log(response);
    } catch (error) {
      alert(error);
    }
  };

  const getChat = async () => {
    if (msg.trim().length <= 0 && !file) {
      alert("Please write a message or upload a file!");
      return;
    }
    setInput(true);

    setDisplayMessages((prevMessages) => [
      ...prevMessages,
      [msg, "sent"],
    ]);
    setMsg("");

    try {
      const formData = new FormData();
      const formattedMessage = `"${msg.trim()}"`;
      formData.append("text", formattedMessage);
      formData.append("lang", "English");
      formData.append("userid", user.id);
      if (file) formData.append("file", file);

      const res = await axios.post("https://api.heygyani.com/ind_Elaborate/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setDisplayMessages((prevMessages) => [
        ...prevMessages,
        [res.data, "rcvd"],
      ]);

      props.type === 'chatpdf' ? localStorage.setItem("chatpdfchats", JSON.stringify(displayMessages)) : localStorage.setItem("chats", JSON.stringify(displayMessages))

      setFile(null);
    } catch (error) {
      console.error("Error fetching chat response:", error);
    } finally {
      setInput(false);
    }
  };

  const saveChats = () => {
    localStorage.setItem("chats", JSON.stringify(displayMessages));
    console.log("Chats Saved Locally");
  };

  function formatMessage(message) {
    const lines = message.split("\n");
    return lines.map((line, index) => {
      if (line.startsWith("## ")) {
        return <h2 key={index} className="text-xl font-bold mb-2">{line.replace("## ", "")}</h2>;
      } else if (line.startsWith("### ")) {
        return <h3 key={index} className="text-lg font-semibold mb-1">{line.replace("### ", "")}</h3>;
      } else if (line.includes("**")) {
        return (
          <p key={index} className="mb-2">
            {line.split("**").map((part, idx) => (idx % 2 === 1 ? <strong key={idx}>{part}</strong> : part))}
          </p>
        );
      } else {
        return <p key={index} className="mb-2">{line}</p>;
      }
    });
  }

  return (
    <div className="flex flex-col w-full bg-gray-900 text-gray-200">

      {/* Messages Section (Scrollable) */}
      <div
        className="flex-grow overflow-y-auto px-0 pt-20 pb-32 bg-[#f1f5f9]"
        // style={{ scrollbarColor: "#4A5568 #2D3748" }}
      >
        {displayMessages.map((msg, i) => (
          <div key={i} className={`flex !text-sm ${msg[1] === "sent" ? "justify-end" : "justify-start"}`}>
            <div
              className={`max-w-xs px-2 py-1 m-2 rounded-xl text-left text-sm ${
                msg[1] === "sent" ? "bg-blue-200 text-gray-800" : "bg-gray-200 text-gray-800"
              }`}
            >
              {formatMessage(msg[0])}
            </div>
          </div>
        ))}
        <div ref={bottomRef} className="h-0 py-0 my-0 gap-0" />
      </div>

      {/* Input Box (Fixed at Bottom) */}
      <div className={`flex items-center py-3 bg-gray-900 border-t border-gray-700 bottom-0 ${props.type === 'chatpdf' ? 'flex' : 'fixed'} z-10`}>
        <div className="flex items-center ">
        <TextInput
          radius="xl"
          size="md"
          className="flex-grow text-white px-2 py-2"
          value={msg}
          placeholder="Type a message..."
          onChange={(e) => setMsg(e.target.value)}
          onKeyDown={(e) => {
            if (e.shiftKey && e.key === "Enter") setMsg(msg + "\n");
            else if (e.key === "Enter" && !input && !e.shiftKey) getChat();
          }}
          leftSection={
            <IconButton component="label" color="primary">
            <AttachFile />
            <input type="file" hidden onChange={(e) => setFile(e.target.files[0])} />
          </IconButton>
          }
        />
          
          <ActionIcon size={40} radius="xl" variant="filled" onClick={getChat} color="blue">
            <Send style={{ width: 24, height: 24 }} />
          </ActionIcon>
        </div>
      </div>
    </div>
  );
}

export default AIBot;