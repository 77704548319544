import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Plans(){
    const [tab, settab] = useState(0);
    const navigate = useNavigate();


    return(
        <section
        className="text-gray-600 body-font overflow-hidden scrollbar-hide mx-5 w-full"
        style={{ fontFamily: "Work Sans" }}
      >
        <div
          className="container px-5 py-2 mx-auto"
          style={{ fontFamily: "Work Sans" }}
        >
          <div className="flex flex-col text-left w-full mb-6">
            <h1
              className="sm:text-4xl text-3xl w-full title-font font-bold mb-1 text-gray-900"
              style={{ fontFamily: "Work Sans" }}
            >
              Pricing
            </h1>
          </div>
          {tab == 0 && (
            <>
              <div className="flex flex-wrap justify-center -m-4 w-full">
                <div className="w-full p-5 xl:w-[30%] md:w-1/2 w-full  max-[470px]:w-3/4">
                  <div className="h-full p-8 bg-white rounded-lg drop-shadow-md border-2  flex flex-col relative overflow-hidden">
                    <h1
                      className="text-4xl text-red-400 font-bold mb-1  leading-none"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      Free
                    </h1>
                    <h2
                      className="text-xl tracking-widest title-font text-black mb-4 font-bold"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      Gyani Lite
                    </h2>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      100000 words
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      Limited WhatsApp
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      Access to notes
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      Trial for AI Ink
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      Trial for AI Talk
                    </p>
                    <p
                      className="flex items-center text-black font-bold"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      1 language
                    </p>
                    <button
                      className="border border-transparent mt-auto hover:border-blue-700 font-bold bg-blue-500 hover:bg-blue-700 text-white  flex flex-row justify-center items-center space-x-2 py-2 rounded w-full px-2"
                      onClick={() => {
                        navigate("/payable", {
                          state: {
                            amount: 0,
                            credits: 100000,
                          },
                        });
                      }}
                      style={{ fontFamily: "Work Sans" }}
                    >
                      Subscribe
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="w-4 h-4 ml-auto"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </button>
                    {/* <p className="text-xs text-gray-500 mt-3" style={{fontFamily:"Work Sans"}}>
                  Literally you probably haven't heard of them jean shorts.
                </p> */}
                  </div>
                </div>
                <div className="p-5 xl:w-[30%] md:w-1/2 w-full max-[470px]:w-3/4">
                  <div className="h-full p-8 rounded-lg border-2 drop-shadow-md bg-white flex flex-col relative overflow-hidden">
                    {/* <span className="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl" style={{fontFamily:"Work Sans"}}>
                  POPULAR
                </span> */}
                    <h1 className="text-4xl text-red-400 font-bold leading-none flex items-center  mb-1 ">
                      <span style={{ fontFamily: "Work Sans" }}>$40 </span>
                      <span
                        className="text-lg ml-1 font-normal text-gray-500"
                        style={{ fontFamily: "Work Sans" }}
                      >
                        one time
                      </span>
                    </h1>
                    <h2
                      className="text-xl tracking-widest text-black title-font mb-4 font-bold"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      Gyani Plus
                    </h2>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span
                        className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0"
                        style={{ fontFamily: "Work Sans" }}
                      >
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      750000 words
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      Whatsapp bot
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      Access to notes
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      AI Ink
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      AI Talk Limited
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      Connect Google Drive, Dropbox and Microsoft
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-6"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      10+ languages
                    </p>
                    <button
                      className="flexborder border-transparent mt-auto hover:border-blue-700 font-bold bg-blue-500 hover:bg-blue-700 text-white  flex flex-row justify-center items-center space-x-2 py-2 rounded w-full px-2"
                      onClick={() => {
                        navigate("/payable", {
                          state: {
                            amount: 40,
                            credits: 750000,
                          },
                        });
                      }}
                      style={{ fontFamily: "Work Sans" }}
                    >
                      Subscribe
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="w-4 h-4 ml-auto"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </button>
                    {/* <p className="text-xs text-gray-500 mt-3" style={{fontFamily:"Work Sans"}}>
                  Literally you probably haven't heard of them jean shorts.
                </p> */}
                  </div>
                </div>
                <div className="p-5 xl:w-[30%] md:w-1/2 w-full max-[470px]:w-3/4">
                  <div className="h-full p-8 rounded-lg border-2 drop-shadow-md bg-white flex flex-col relative overflow-hidden">
                    <h1
                      className="text-4xl text-red-400 font-bold leading-none flex items-center  mb-1 "
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span>$120</span>
                      <span className="text-lg ml-1 font-normal text-gray-500">
                        /year
                      </span>
                    </h1>
                    <h2
                      className="text-xl tracking-widest text-black title-font  mb-4 font-bold"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      Maha Gyani
                    </h2>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      Unlimited Words
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      Whatsapp bot
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      Access to notes
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      AI Ink
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      AI Talk Unlimited
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-2"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      Third party integrations with API
                    </p>
                    <p
                      className="flex items-center text-black font-bold mb-6"
                      style={{ fontFamily: "Work Sans" }}
                    >
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-lime-400 text-green-700 font-bold rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                      </span>
                      20+ languages
                    </p>
                    <button
                      className="border border-transparent mt-auto hover:border-blue-700 font-bold bg-blue-500 hover:bg-blue-700 text-white  flex flex-row justify-center items-center space-x-2 py-2 rounded w-full px-2"
                      onClick={() => {
                        navigate("/payable", {
                          state: {
                            amount: 120,
                            credits: 750000,
                          },
                        });
                      }}
                    >
                      Subscribe
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="w-4 h-4 ml-auto"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </button>
                    {/* <p className="text-xs text-gray-500 mt-3">
                  Literally you probably haven't heard of them jean shorts.
                </p> */}
                  </div>
                </div>
              </div>
            </>
          )}
       </div>
      </section>
    );
}