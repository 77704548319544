import React, { useState } from "react";
import { Box, CssBaseline, useMediaQuery } from "@mui/material";
import Sidebar from "./components/Sidebar"; // Assuming Sidebar is your sidebar component
import { Routes, Route, Navigate } from "react-router-dom";
import HomeLanding from "./components/research/HomeLanding";
import MyNotes from "./components/research/MyNotes";
import Bookmarks from "./components/research/Bookmarks";
import AIBot from "./components/SummaryGenerator/AIBot";
import Settings from "./components/Settings";
import Profile from "./components/user-profile/Profile";
import Subscriptions from "./components/Subsriptions/Subscriptions";
import NotFound from "./components/NotFound";
import Header from "./components/Header";
import ChatBar from "./components/ChatBar";

function App() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isChatBarOpen, setChatBarOpen] = useState(false);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between",alignItems: "stretch" }}>
      {/* <CssBaseline /> */}
      {/* Sidebar */}
      <Box sx={{ minHeight: "100vh", zIndex: 100 }}>
        <Sidebar isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen}/>
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          flexGrow: 1,
          // ml: isSidebarOpen ? "230px" : 0,
          // pl: 2,
          mt: 0,
          overflowY: "auto", // Allow scrollable content
          transition: "margin 0.1s ease", // Smooth transition
        }}
      >
        <Header isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen} isChatBarOpen={isChatBarOpen} setChatBarOpen={setChatBarOpen}/>
        <Routes>
          <Route path="/" element={<HomeLanding/>} />
          <Route path="/home" element={<HomeLanding />} />
          <Route path="/mynotes" element={<MyNotes open={isSidebarOpen} />} />
          <Route path="/bookmarks" element={<Bookmarks />} />
          {/* <Route path="/chatbot" element={<AIBot isSidebarOpen={isSidebarOpen} />} /> */}
          <Route path="/settings" element={<Settings />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Box>
      <Box sx={{ minHeight: "100vh", zIndex: 100 }}>
        <ChatBar isChatBarOpen={isChatBarOpen} setChatBarOpen={setChatBarOpen}/>
      </Box>
    </Box>
  );
}

export default App;
