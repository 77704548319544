import type {
  API,
  InlineTool,
  InlineToolConstructorOptions,
} from "@editorjs/editorjs";

interface TemplateInlineToolConfig {
  buttonHTML?: string;
  html: string;
  setResearchDialog: any;
  setInlineResult: any;
  setGlowUp: any;
  setGenerateDialog: any;
  setInlineMenu: any;
  clearSelection: any;
  handleResearchMenuPosition: any;
  handleCurrentBlock: any;
}

interface TemplateInlineToolConstructorOptions
  extends InlineToolConstructorOptions {
  config?: TemplateInlineToolConfig;
}

class InlineResearchButton implements InlineTool {
  static isSurroundEnabled: boolean = true;

  static get isInline() {
    return true;
  }

  static get title() {
    return "Generate with AI";
  }

  #api: API;
  #config!: TemplateInlineToolConfig;

  constructor({ api, config }: TemplateInlineToolConstructorOptions) {
    this.#api = api;
    if (config && "html" in config) {
      this.#config = config;
    }
  }

  get shortcut() {
    return "CMD+R";
  }

  checkState() {
    return false;
  }

  render() {
    const button = document.createElement("button");

    button.classList.add(this.#api.styles.inlineToolButton);
    button.type = "button";

    button.innerHTML =
      this.#config.buttonHTML ??
      `
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
          <path d="M336 64h32a48 48 0 0148 48v320a48 48 0 01-48 48H144a48 48 0 01-48-48V112a48 48 0 0148-48h32" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/>
          <rect x="176" y="32" width="100" height="64" rx="26.13" ry="26.13" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/>
        </svg>
      `;

    button.onclick = (e) => {
      this.#config.setResearchDialog(true);
      this.#config.setInlineResult(document.getSelection()?.toString());
      this.#config.setGlowUp(true);
      this.#config.setGenerateDialog(true);
      this.#config.setInlineMenu(true);
      this.#config.clearSelection();
      this.#config.handleResearchMenuPosition(e);
      this.#config.handleCurrentBlock();
    };

    return button;
  }

  surround() {
    if (!InlineResearchButton.isSurroundEnabled) {
      return;
    }

    // document.execCommand("insertHTML", false, this.#config.html);

    InlineResearchButton.isSurroundEnabled = false;

    setTimeout(() => (InlineResearchButton.isSurroundEnabled = true));
  }
}

export { InlineResearchButton };
export type { TemplateInlineToolConfig };
