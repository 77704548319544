import React, { useState } from "react";
import {
    IconButton,
    Box,
} from "@mui/material";
import {
    ChevronRight,
} from "@mui/icons-material";
import AIBot from "./SummaryGenerator/AIBot";

const ChatBar = ({ isChatBarOpen, setChatBarOpen }) => {

    const toggleChatBar = () => {
        setChatBarOpen(!isChatBarOpen);
    };

    const ChatBarContent = (
        <Box
            sx={{
                width: isChatBarOpen ? 300 : 0,
                transition: "width 0.2s",
                backgroundColor: "#f1f5f9",
                height: "100vh",
                pt: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                borderRight: "1px solid #D1D5DB",
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "start", gap: '2px', alignItems: "center", px: 1 }}>
                <IconButton onClick={toggleChatBar}>
                    {isChatBarOpen ? <ChevronRight /> : <div />}
                </IconButton>
                {isChatBarOpen && (
                    <p className="font-bold text-gray-500">AI Chat</p>
                )}
            </Box>

            <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
                <AIBot />
            </Box>

        </Box>
    );

    return ChatBarContent;
};

export default ChatBar;