import { Chat, Person, Settings, Timer } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";

const Header = (props) => {
  return (
    <div className="flex h-10 my-2 items-center justify-between bg-white px-2 lg:px-6 py-6">
      {/* Left Section */}
      <div className="flex items-center space-x-3">
        <button className="p-2 rounded-md hover:bg-gray-100 focus:outline-none" onClick={()=>props.setSidebarOpen(!props.isSidebarOpen)}>
          {/* Hamburger Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
        <span className="text-lg font-medium text-gray-800 lg:block sm:hidden md:hidden">Zain</span>
      </div>

      {/* Right Section */}
      <div className="flex judtify-center items-center space-x-6">

        {/* Icons */}
        <div className="flex justify-center items-center gap-4">
          {/* Settings Icon */}
          <button className="rounded-full hover:bg-gray-100 focus:outline-none">
            <Link to="/settings"><Settings htmlColor={'#757575'} fontSize="small"/></Link>
          </button>

          {/* Clock Icon */}
          <button className="rounded-full hover:bg-gray-100 focus:outline-none" onClick={()=>props.setChatBarOpen(!props.isChatBarOpen)}>
            <Chat htmlColor={'#757575'} fontSize="small"/>
          </button>

          {/* Moon Icon */}
          <button className="rounded-full hover:bg-gray-100 focus:outline-none">
            <Link to='/profile'><Person htmlColor={'#757575'} fontSize="small"/></Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
