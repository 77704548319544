import { useState, useEffect, useRef } from "react";
import {
  Button,
  Combobox,
  MantineProvider,
  Menu,
  TextInput,
  Textarea,
  useCombobox,
} from "@mantine/core";
import "@mantine/core/styles.css";
import classes from "./InlineMenu.module.css";
import {
  NavigateNext,
  AutoAwesomeRounded,
  ArticleOutlined,
  AirOutlined,
  AutorenewOutlined,
  ReceiptLongOutlined,
  TranslateOutlined,
  BloodtypeOutlined,
  ImportContactsOutlined,
  DriveFileRenameOutline,
  KeyboardArrowRightOutlined,
  KeyboardArrowLeftOutlined,
  Done,
  Add,
  RotateLeft,
  DeleteForever,
  ArrowBack,
} from "@mui/icons-material";
export function InlineMenu(props) {
  const combobox = useCombobox();
  const inlineMenuRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState<string[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [menu, setMenu] = useState<boolean>(true);
  // const [props.aiText, props.setAiText] = useState<string[]>([]);
  const [currInd, setCurrInd] = useState<number>(0);
  const item = "";
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        inlineMenuRef.current &&
        !inlineMenuRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest(".mantine-Menu-dropdown")
      ) {
        props.handleInlineResult([]);
        props.setShowInlineResult([]);
        props.setInlineMenu(false);
        props.setShowBottomBar(true);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [props]);
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    props.setAiText(inputValue);
    props.setInlineResult(e.target.value);
  };
  const handleContextMenuChange = (e) => {
    setIsSearching(true);
    setMenu(false);
    props.handleInlineContextMenu(e);
  };
  const handleEnter = async (e) => {
    setIsSearching(true);
    setMenu(false);
    props.elaborateInlineCustom(props.aiText);
    props.setAiText([]);
    props.handleInlineResult(
      props.showInlineResult
        ? props.showInlineResult[props.showInlineResult.length]
        : ""
    );
  };
  const handleTry = () => {
    props.handleTryAgain(props.aiText);
    props.setAiText("");
  };
  const handleReplace = () => {
    props.handleReplace(props.aiText);
  };

  useEffect(() => {
    setCurrInd(props.showInlineResult ? props.showInlineResult.length - 1 : 0);
  }, [props.showInlineResult]);
  useEffect(() => {
    props.handleInlineResult(
      props.showInlineResult ? props.showInlineResult[currInd] : ""
    );
  }, [props.handleInlineResult, currInd, props]);
  return (
    <MantineProvider>
      <div
        ref={inlineMenuRef}
        style={{
          position: "absolute",
          top: `${(props.mouseY - 0 + window.pageYOffset + (inlineMenuRef.current?.offsetHeight || 0) > window.innerHeight
              ? props.mouseY - 0 - (inlineMenuRef.current?.offsetHeight || 0)
              : props.mouseY - 0)
            + window.pageYOffset
            }px`,
          left: `${props.mouseX - 200 + window.pageXOffset}px`,
          zIndex: 500,
        }}
      >


        <div className="flex justify-between mb-2 mt-2 !w-[32rem]">
          <div className="relative w-full p-0">
            {isSearching && (
              <>
                <div className="overflow-auto">
                  <Textarea
                    classNames={{ input: classes.inputT }}
                    autosize
                    maxRows={15}
                    minRows={2}
                    defaultValue={
                      props.showInlineResult
                        ? props.showInlineResult[currInd]
                        : ""
                    }
                  />
                </div>
                {props.showInlineResult &&
                  props.showInlineResult.length !== 0 && (
                    <div className="flex justify-end !h-2">
                      <Button
                        variant="outline"
                        py={0}
                        px={0}
                        color="white"
                        disabled={currInd === 0}
                        onClick={() => {
                          if (currInd !== 0) setCurrInd(currInd - 1);
                        }}
                        className="!text-black   !transform !translate-y-[-2rem]  !mr-1 !h-6"
                      >
                        <KeyboardArrowLeftOutlined fontSize="small" />
                      </Button>
                      <div className="!text-black text-sm   !transform !translate-y-[-2rem]  !pt-[0.1rem] !h-6">
                        {currInd + 1}/
                        {props.showInlineResult
                          ? props.showInlineResult.length
                          : 1}
                      </div>
                      <Button
                        variant="outline"
                        py={0}
                        px={0}
                        color="white"
                        disabled={currInd + 1 === props.showInlineResult.length}
                        onClick={() => {
                          if (currInd + 1 !== props.showInlineResult.length)
                            setCurrInd(currInd + 1);
                        }}
                        className="!text-black  !transform !translate-y-[-2rem] !mr-1  !ml-1 !h-6"
                      >
                        <KeyboardArrowRightOutlined fontSize="small" />
                      </Button>
                    </div>
                  )}
              </>
            )}
            <div className="relative w-full">
              <TextInput
                className="!w-full"
                placeholder="Ask Gyani AI anything"
                classNames={{ input: classes.input }}
                defaultValue={props.inlineResult}
                // value={props.showInlineResult ? props.inlineResult : props.aiText} 
                onChange={handleInputChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleEnter(e);
                  }
                }}
                onSelect={e => e.preventDefault()}
              />
              <Button
                py={0}
                px={0}
                color="blue"
                onClick={handleEnter}
                className="!absolute !right-0 !top-1/2 !transform -translate-y-1/2 !mr-1 !h-6"
              >
                <KeyboardArrowRightOutlined fontSize="small" />
              </Button>
              <AutoAwesomeRounded
                fontSize="small"
                color="primary"
                className="!absolute !left-0 !top-1/2 !p-1 !transform -translate-y-1/2 !mr-1 !h-6"
              />
            </div>
          </div>
        </div>
        {menu ? (
          <Combobox store={combobox}>
            <div className={classes.list} style={{ width: '230px' }}>
              <Combobox.Options>
                <Combobox.Group label="Edit or Review" fw={200}>
                  <Combobox.Option
                    value={item}
                    active={value.includes(item)}
                    onMouseOver={() => combobox.resetSelectedOption()}
                    className="!py-1"
                  >
                    <div
                      className="!px-2 !text-[0.875rem] !text-black"
                      onClick={handleContextMenuChange}
                    >
                      <AirOutlined fontSize="small" /> Improve Fluency
                    </div>
                  </Combobox.Option>
                  <Combobox.Option
                    value={item}
                    active={value.includes(item)}
                    onMouseOver={() => combobox.resetSelectedOption()}
                    className="!py-1"
                  >
                    <Menu shadow="md" width={200} position="right">
                      <Menu.Target>
                        <div
                          className="!flex !items-center !justify-between"
                          onClick={(event) => {
                            props.handleInlineContextSubMenuPrimaryOption(
                              "Paraphrase"
                            );
                          }}
                        >
                          <div className="!px-2 !text-[0.875rem] !text-black">
                            <ArticleOutlined fontSize="small" /> Paraphrase
                          </div>
                          <div>
                            <NavigateNext />
                          </div>
                        </div>
                      </Menu.Target>
                      <Menu.Dropdown style={{ zIndex: 2000, width: '230px' }}>
                        <Menu.Label>Select paraphrasing style</Menu.Label>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);
                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          Academically
                        </Menu.Item>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);

                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          Casually
                        </Menu.Item>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);

                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          Persuasively
                        </Menu.Item>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);

                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          Boldly
                        </Menu.Item>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);

                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          Friendly
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Combobox.Option>
                  <Combobox.Option
                    value={item}
                    active={value.includes(item)}
                    onMouseOver={() => combobox.resetSelectedOption()}
                    className="!py-1"
                  >
                    <Menu shadow="md" width={200} position="right">
                      <Menu.Target>
                        <div
                          className="!flex !items-center !justify-between"
                          onClick={(event) => {
                            props.handleInlineContextSubMenuPrimaryOption(
                              "Simplify"
                            );
                          }}
                        >
                          <div className="!px-2 !text-[0.875rem] !text-black">
                            <AutorenewOutlined fontSize="small" /> Simplify
                          </div>
                          <div>
                            <NavigateNext />
                          </div>
                        </div>
                      </Menu.Target>
                      <Menu.Dropdown style={{ zIndex: 2000, width: '230px' }}>
                        <Menu.Label>Select simplifying style</Menu.Label>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);

                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          Academically
                        </Menu.Item>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);

                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          Casually
                        </Menu.Item>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);

                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          Persuasively
                        </Menu.Item>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);

                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          Boldly
                        </Menu.Item>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);

                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          Friendly
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Combobox.Option>
                  <Combobox.Option
                    value={item}
                    active={value.includes(item)}
                    onMouseOver={() => combobox.resetSelectedOption()}
                    className="!py-1"
                  >
                    <div
                      className="!px-2 !text-[0.875rem] !text-black"
                      onClick={handleContextMenuChange}
                    >
                      <ReceiptLongOutlined fontSize="small" /> Make Longer
                    </div>
                  </Combobox.Option>
                  <Combobox.Option
                    value={item}
                    active={value.includes(item)}
                    onMouseOver={() => combobox.resetSelectedOption()}
                    className="!py-1"
                  >
                    <Menu shadow="md" width={200} position="right">
                      <Menu.Target>
                        <div
                          className="!flex !items-center !justify-between"
                          onClick={(event) => {
                            props.handleInlineContextSubMenuPrimaryOption(
                              "Translate"
                            );
                          }}
                        >
                          <div className="!px-2 !text-[0.875rem] !text-black">
                            <TranslateOutlined fontSize="small" /> Translate
                          </div>
                          <div>
                            <NavigateNext />
                          </div>
                        </div>
                      </Menu.Target>
                      <Menu.Dropdown style={{ zIndex: 2000, width: '230px' }}>
                        <Menu.Label>Select translate language</Menu.Label>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);

                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          Spanish
                        </Menu.Item>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);

                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          German
                        </Menu.Item>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);

                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          French
                        </Menu.Item>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);

                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          Hindi
                        </Menu.Item>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);

                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          Japanese
                        </Menu.Item>
                        <Menu.Item
                          onClick={(e) => {
                            setIsSearching(true);
                            setMenu(false);

                            props.handleInlineContextSubMenuSecondary(e);
                          }}
                        >
                          English
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Combobox.Option>
                </Combobox.Group>
                <Combobox.Group label="Generate from Selection">
                  <Combobox.Option
                    value={item}
                    active={value.includes(item)}
                    onMouseOver={() => combobox.resetSelectedOption()}
                    className="!py-1"
                  >
                    <div
                      className="!px-2 !text-[0.875rem] !text-black"
                      onClick={handleContextMenuChange}
                    >
                      <BloodtypeOutlined fontSize="small" /> Write opposing
                      argument
                    </div>
                  </Combobox.Option>
                  <Combobox.Option
                    value={item}
                    active={value.includes(item)}
                    onMouseOver={() => combobox.resetSelectedOption()}
                    className="!py-1"
                  >
                    <div
                      className="!px-2 !text-[0.875rem] !text-black"
                      onClick={handleContextMenuChange}
                    >
                      <ImportContactsOutlined fontSize="small" /> Write with
                      more depth
                    </div>
                  </Combobox.Option>
                </Combobox.Group>
                <Combobox.Group label="Write">
                  <Combobox.Option
                    value={item}
                    active={value.includes(item)}
                    onMouseOver={() => combobox.resetSelectedOption()}
                    className="!py-1"
                  >
                    <div
                      className="!px-2 !text-[0.875rem] !text-black"
                      onClick={handleContextMenuChange}
                    >
                      <DriveFileRenameOutline fontSize="small" /> Continue
                      writing
                    </div>
                  </Combobox.Option>
                </Combobox.Group>
              </Combobox.Options>
            </div>
          </Combobox>
        ) : (
          <Combobox store={combobox}>
            <div className={classes.list}>
              <Combobox.Options>
                <Combobox.Group fw={200}>
                  <Combobox.Option
                    value={item}
                    active={value.includes(item)}
                    onMouseOver={() => combobox.resetSelectedOption()}
                    className="!py-1"
                  >
                    <div
                      className="!px-2 !text-[0.875rem] !text-black"
                      onClick={props.handleInsert}
                    >
                      <Done fontSize="small" />
                      Insert
                    </div>
                  </Combobox.Option>

                  <Combobox.Option
                    value={item}
                    active={value.includes(item)}
                    onMouseOver={() => combobox.resetSelectedOption()}
                    className="!py-1"
                  >
                    <div
                      className="!px-2 !text-[0.875rem] !text-black"
                      onClick={handleTry}
                    >
                      <RotateLeft fontSize="small" /> Try again
                    </div>
                  </Combobox.Option>
                  <Combobox.Option
                    value={item}
                    active={value.includes(item)}
                    onMouseOver={() => combobox.resetSelectedOption()}
                    className="!py-1"
                  >
                    <div
                      className="!px-2 !text-[0.875rem] !text-black"
                      onClick={handleReplace}
                    >
                      <RotateLeft fontSize="small" /> Replace Selection
                    </div>
                  </Combobox.Option>
                  <Combobox.Option
                    value={item}
                    active={value.includes(item)}
                    onMouseOver={() => combobox.resetSelectedOption()}
                    className="!py-1"
                  >
                    <div
                      className="!px-2 !text-[0.875rem] !text-black"
                      onClick={props.handleDiscard}
                    >
                      <DeleteForever fontSize="small" /> Discard
                    </div>
                  </Combobox.Option>
                  <Combobox.Option
                    value={item}
                    active={value.includes(item)}
                    onMouseOver={() => combobox.resetSelectedOption()}
                    className="!py-1"
                  >
                    <div
                      className="!px-2 !text-[0.875rem] !text-black"
                      onClick={() => {
                        setMenu(true);
                      }}
                    >
                      <ArrowBack fontSize="small" /> Go back
                    </div>
                  </Combobox.Option>
                </Combobox.Group>
              </Combobox.Options>
            </div>
          </Combobox>
        )}
      </div>
    </MantineProvider>
  );
}
